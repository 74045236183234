// function checkMKT() {
//   let $specialitySelect = $('#statement_of_registration_card_passing_speciality');
//   let $passingMKT = $('#statement_of_registration_card_passing_mkt_required')
//   let $passingMKTHelp = $('#passingMKTHelp')
//
//   if (!['081 Право', '293 Міжнародне право'].includes($specialitySelect.val())) {
//     if ($passingMKT.prop('checked')) $passingMKT.click();
//
//     $passingMKT.prop('disabled', true)
//     $passingMKTHelp.removeClass('d-none')
//   } else {
//     $passingMKT.prop('disabled', false)
//     $passingMKTHelp.addClass('d-none')
//   }
// }

$(document).ready(function () {
  // checkMKT();

  // $('#statement_of_registration_card_passing_speciality').change(function (event) {
  //   checkMKT();
  // });

  $('#statement_of_registration_card_passing_mtnk_required').on('change', function (event) {
    let $passingMTNK = $('#passingMTNK');

    if(event.target.checked) {
      $passingMTNK.find('input').prop('disabled', false);
      $passingMTNK.find('select').prop('disabled', false);
      $passingMTNK.collapse('show');
    } else {
      $passingMTNK.find('input').prop('disabled', true);
      $passingMTNK.find('select').prop('disabled', true);
      $passingMTNK.collapse('hide');
      // resetValues($passingMTNK)
    }
  });

  $('#statement_of_registration_card_passing_mkt_required').on('change', function (event) {
    let $passingMKT = $('#passingMKT');
    let $passingMKTHelp = $('#passingMKTHelp')

    if(event.target.checked) {
      // if (confirm("Для здобуття ступеня магістра за спеціальністю 081 «Право» приймаються особи, які здобули ступінь бакалавра з спеціальностей 081 «Право» або 293 «Міжнародне право», напрямів 6.030401 «Правознавство» або 6.030202 «Міжнародне право».\n\n Ви підтверджуєте виконання цих вимог?")) {
        $passingMKT.find('input').prop('disabled', false);
        $passingMKT.find('select').prop('disabled', false);
        $passingMKT.collapse('show');
        $passingMKTHelp.addClass('d-none');
      // } else {
      //   event.target.checked = false
      // }
    } else {
      $passingMKT.find('input').prop('disabled', true);
      $passingMKT.find('select').prop('disabled', true);
      $passingMKT.collapse('hide');
      $passingMKTHelp.removeClass('d-none');
      // resetValues($passingMKT)
    }
  });

  $('#passing_mkt_requirements').on('change', function (event) {
    if(event.target.checked) {
      if (confirm("Для здобуття ступеня магістра за спеціальністю 081 «Право» приймаються особи, які здобули ступінь бакалавра з спеціальностей 081 «Право» або 293 «Міжнародне право», напрямів 6.030401 «Правознавство» або 6.030202 «Міжнародне право».\n\n Ви підтверджуєте виконання цих вимог?")) {
      } else {
        event.target.checked = false
      }
    }
  });

  $('#statement_of_registration_card_passing_extra_conditions_required').on('change', function (event) {
    let $passingExtraConditions = $('#passingExtraConditions');

    if(event.target.checked) {
      $passingExtraConditions.find('input').prop('disabled', false);
      $passingExtraConditions.collapse('show');
    } else {
      $passingExtraConditions.find('input').prop('disabled', true);
      $passingExtraConditions.collapse('hide');
      // resetValues($passingExtraConditions)
    }
  });

  $('[name="statement_of_registration_card[left_education_completed]"]').on('change', function (event) {
    let $leftEducationNotCompleted = $('#leftEducationNotCompleted');
    let $leftEducationCompleted = $('#leftEducationCompleted');

    if(event.target.value === 'true') {
      $leftEducationNotCompleted.find('input').prop('disabled', true);
      $leftEducationNotCompleted.find('select').prop('disabled', true);
      $leftEducationNotCompleted.collapse('hide');
      // resetValues($leftEducationNotCompleted)

      $leftEducationCompleted.find('input').prop('disabled', false);
      $leftEducationCompleted.find('select').prop('disabled', false);
      $leftEducationCompleted.collapse('show');

    } else {
      $leftEducationCompleted.find('input').prop('disabled', true);
      $leftEducationCompleted.find('select').prop('disabled', true);
      $leftEducationCompleted.collapse('hide');
      // resetValues($leftEducationCompleted)

      $leftEducationNotCompleted.find('input').prop('disabled', false);
      $leftEducationNotCompleted.find('select').prop('disabled', false);
      $leftEducationNotCompleted.collapse('show');
    }
  });

  // $('[name="statement_of_registration_card[delivery_required]"]').on('change', function (event) {
  //   let $deliveryRequired = $('#deliveryRequired');
  //
  //   if(event.target.value === 'true') {
  //     $deliveryRequired.find('input').prop('disabled', false);
  //     $deliveryRequired.collapse('show');
  //   } else {
  //     $deliveryRequired.find('input').prop('disabled', true);
  //     $deliveryRequired.collapse('hide');
  //     // resetValues($deliveryRequired)
  //   }
  // });

  $('#statement_of_registration_card_left_education_document_institution_type').on('change', function (event) {
    let $leftInstitutionUzhNU = $('#leftInstitutionUzhNU');
    let $leftInstitutionMilitary = $('#leftInstitutionMilitary');
    let $leftInstitutionOther = $('#leftInstitutionOther');

    if(event.target.value === 'ДВНЗ "УжНУ"') {
      $leftInstitutionMilitary.find('input').prop('disabled', true);
      $leftInstitutionMilitary.find('select').prop('disabled', true);
      $leftInstitutionMilitary.collapse('hide');

      $leftInstitutionOther.find('input').prop('disabled', true);
      $leftInstitutionOther.find('select').prop('disabled', true);
      $leftInstitutionOther.collapse('hide');

      $leftInstitutionUzhNU.find('input').prop('disabled', false);
      $leftInstitutionUzhNU.find('select').prop('disabled', false);
      $leftInstitutionUzhNU.collapse('show');
    } else if (event.target.value === 'Військовий заклад вищої освіти') {
      $leftInstitutionUzhNU.find('input').prop('disabled', true);
      $leftInstitutionUzhNU.find('select').prop('disabled', true);
      $leftInstitutionUzhNU.collapse('hide');

      $leftInstitutionOther.find('input').prop('disabled', true);
      $leftInstitutionOther.find('select').prop('disabled', true);
      $leftInstitutionOther.collapse('hide');

      $leftInstitutionMilitary.find('input').prop('disabled', false);
      $leftInstitutionMilitary.find('select').prop('disabled', false);
      $leftInstitutionMilitary.collapse('show');
    } else if (event.target.value === 'Інший ЗВО') {
      $leftInstitutionUzhNU.find('input').prop('disabled', true);
      $leftInstitutionUzhNU.find('select').prop('disabled', true);
      $leftInstitutionUzhNU.collapse('hide');

      $leftInstitutionMilitary.find('input').prop('disabled', true);
      $leftInstitutionMilitary.find('select').prop('disabled', true);
      $leftInstitutionMilitary.collapse('hide');

      $leftInstitutionOther.find('input').prop('disabled', false);
      $leftInstitutionOther.find('select').prop('disabled', false);
      $leftInstitutionOther.collapse('show');
    } else {
      $leftInstitutionUzhNU.find('input').prop('disabled', true);
      $leftInstitutionUzhNU.find('select').prop('disabled', true);
      $leftInstitutionUzhNU.collapse('hide');

      $leftInstitutionMilitary.find('input').prop('disabled', true);
      $leftInstitutionMilitary.find('select').prop('disabled', true);
      $leftInstitutionMilitary.collapse('hide');

      $leftInstitutionOther.find('input').prop('disabled', true);
      $leftInstitutionOther.find('select').prop('disabled', true);
      $leftInstitutionOther.collapse('hide');
    }
  });

  $('#statement_of_registration_card_passing_mtnk_location').change(function (event) {
    $('#statement_of_registration_card_passing_mkt_location').val(event.target.value);
  })

  $('#statement_of_registration_card_passing_mkt_location').change(function (event) {
    $('#statement_of_registration_card_passing_mtnk_location').val(event.target.value);
  })
});

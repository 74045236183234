$(document).ready(function () {
  let $filter_form = $('#calculator_specialities_filter');
  let $filter_form_checkboxes = $('.filter_form_checkbox');
  let $filter_form_selects = $('.select2-search');
  let $filter_form_names = $('.subject_name');
  let $filter_form_calculation_element = $('.calculation_element');
  let $filter_form_scores = $('.subject_score');
  let $subjects_card_header = $('#subjects, #subjects_nmt');
  let $subjects_card_body = $('#subjects_colapse, #subjects_nmt_colapse');
  let $subjects_evi_efvv_card_header = $('#subjects_evi_efvv');
  let $subjects_evi_efvv_card_body = $('#subjects_evi_efvv_colapse');
  let $qualification_group_name_card_header = $('#qualification_group_id');
  let $qualification_group_name_card_body = $('#qualification_group_id_colapse');
  let $id_course_card_header = $('#id_course');
  let $id_course_card_body = $('#id_course_colapse');
  let $education_base_id_colapse =  $('#education_base_id_colapse');
  let $education_base_id_colapse_inputs = $education_base_id_colapse.find('input');

  $education_base_id_colapse_inputs.on('change', function() {
     $education_base_id_colapse_inputs.not(this).prop('checked', false);
     $education_base_id_colapse_inputs.not(this).parent().removeClass('active', false);
  });

  $filter_form_checkboxes.on('input', function (e) { on_select_filter($(e.target).attr('name')) });
  $filter_form_selects.on('input', function (e) { on_select_filter($(e.target).attr('name')) });
  $filter_form_scores.on('input', function (e) { on_select_filter($(e.target).attr('name')) });

  $filter_form_selects.select2({
    placeholder: 'Пошук...',
    allowClear: true,
    multiple: true,
    width: '100%'
  });

  $('#copy_url').on('click', function () {
    let url = window.location.origin + window.location.pathname + '?' + $filter_form.serialize();
    let $temp = $("<input>");
    $("body").append($temp);
    $temp.val(url).select();
    document.execCommand("copy");
    $temp.remove();
    setTimeout("$('#copy_url').popover('hide')", 2000);
  });

  check_education_base_id_pzso();

  $('.disable-events-js').click(function (e) {
    e.stopPropagation();
    window.open(e.target.closest('.disable-events-js').dataset.url, '_blank');
  });

  if($filter_form.serialize().length > 0) {
    $.each($filter_form.serializeArray(), function (index, value) {
      if(value['name'] != 'calculation') {
        filter_detect_selected_menus(value['name']);
      }
    });
  } else {
    $education_base_id_colapse.collapse('show');
  }

  function check_education_base_id_pzso() {
    if($('.education_base_id_pzso').find('input').prop('checked')) {
      $subjects_card_header.removeClass('disabled');
      $subjects_card_header.attr('data-toggle', 'collapse');
      $subjects_card_body.find('input:not(.subject_score)').prop('disabled', false);
      $subjects_card_body.collapse('show');

      $subjects_evi_efvv_card_header.addClass('disabled');
      $subjects_evi_efvv_card_header.attr('data-toggle', '');
      $subjects_evi_efvv_card_body.find('input').prop('disabled', true);
      $subjects_evi_efvv_card_body.find('label').addClass('disabled');
      $subjects_evi_efvv_card_body.collapse('hide');

      $qualification_group_name_card_header.removeClass('disabled');
      $qualification_group_name_card_header.attr('data-toggle', 'collapse');
      $qualification_group_name_card_body.find('input').prop('disabled', false);

      $id_course_card_header.addClass('disabled');
      $id_course_card_header.attr('data-toggle', '');
      $id_course_card_body.find('input').prop('disabled', true);
      $id_course_card_body.find('label').addClass('disabled');
      $id_course_card_body.collapse('hide');

      $filter_form_scores.each(function (index, element) {
        let $element = $(element);
        let is_calculation_checked = $('[name="calculation"]').prop('checked');
        $element.prop('disabled', !(is_calculation_checked && $element.parent().find('input').prop('checked')));
      });
    } else if($('.education_base_id_okr').find('input').prop('checked')) {
      $subjects_card_header.removeClass('disabled');
      $subjects_card_header.attr('data-toggle', 'collapse');
      $subjects_card_body.find('input:not(.subject_score)').prop('disabled', false);
      $subjects_card_body.collapse('show');

      $subjects_evi_efvv_card_header.addClass('disabled');
      $subjects_evi_efvv_card_header.attr('data-toggle', '');
      $subjects_evi_efvv_card_body.find('input').prop('disabled', true);
      $subjects_evi_efvv_card_body.find('label').addClass('disabled');
      $subjects_evi_efvv_card_body.collapse('hide');

      $qualification_group_name_card_header.removeClass('disabled');
      $qualification_group_name_card_header.attr('data-toggle', 'collapse');
      $qualification_group_name_card_body.find('input').prop('disabled', false);

      $id_course_card_header.removeClass('disabled');
      $id_course_card_header.attr('data-toggle', 'collapse');
      $id_course_card_body.find('input').prop('disabled', false);

      $filter_form_scores.each(function (index, element) {
        let $element = $(element);
        let is_calculation_checked = $('[name="calculation"]').prop('checked');
        $element.prop('disabled', !(is_calculation_checked && $element.parent().find('input').prop('checked')));
      });
    } else if($('.education_base_id_bachelor').find('input').prop('checked')) {
      $subjects_card_header.addClass('disabled');
      $subjects_card_header.attr('data-toggle', '');
      $subjects_card_body.find('input').prop('disabled', true);
      $subjects_card_body.find('label').addClass('disabled');
      $subjects_card_body.collapse('hide');

      $subjects_evi_efvv_card_header.removeClass('disabled');
      $subjects_evi_efvv_card_header.attr('data-toggle', 'collapse');
      $subjects_evi_efvv_card_body.find('input:not(.subject_score)').prop('disabled', false);
      $subjects_evi_efvv_card_body.collapse('show');

      $qualification_group_name_card_header.addClass('disabled');
      $qualification_group_name_card_header.attr('data-toggle', '');
      $qualification_group_name_card_body.find('input').prop('disabled', true);
      $qualification_group_name_card_body.find('label').addClass('disabled');
      $qualification_group_name_card_body.collapse('hide');

      $id_course_card_header.addClass('disabled');
      $id_course_card_header.attr('data-toggle', '');
      $id_course_card_body.find('input').prop('disabled', true);
      $id_course_card_body.find('label').addClass('disabled');
      $id_course_card_body.collapse('hide');
      $subjects_evi_efvv_card_body.find('.subject_score').each(function (index, element) {
        let $element = $(element);
        let is_calculation_checked = $('[name="calculation"]').prop('checked');
        $element.prop('disabled', !(is_calculation_checked && $element.parent().find('input').prop('checked')));
      });
    } else {
      $subjects_card_header.addClass('disabled');
      $subjects_card_header.attr('data-toggle', '');
      $subjects_card_body.find('input').prop('disabled', true);
      $subjects_card_body.find('label').addClass('disabled');
      $subjects_card_body.collapse('hide');

      $subjects_evi_efvv_card_header.addClass('disabled');
      $subjects_evi_efvv_card_header.attr('data-toggle', '');
      $subjects_evi_efvv_card_body.find('input').prop('disabled', true);
      $subjects_evi_efvv_card_body.find('label').addClass('disabled');
      $subjects_evi_efvv_card_body.collapse('hide');

      $qualification_group_name_card_header.addClass('disabled');
      $qualification_group_name_card_header.attr('data-toggle', '');
      $qualification_group_name_card_body.find('input').prop('disabled', true);
      $qualification_group_name_card_body.find('label').addClass('disabled');
      $qualification_group_name_card_body.collapse('hide');

      $id_course_card_header.addClass('disabled');
      $id_course_card_header.attr('data-toggle', '');
      $id_course_card_body.find('input').prop('disabled', true);
      $id_course_card_body.find('label').addClass('disabled');
      $id_course_card_body.collapse('hide');
    }
  }

  function on_select_filter(input_name) {
    $('.atestat').find('input').prop('checked', $('.subject_calculation').find('input').prop('checked'));

    if(input_name.search('subjects') >= 0){
      $('[name="' + input_name + '"][type=number]').prop('disabled', !($('[name="calculation"]').prop('checked') &&
                                                                     $('[name="' + input_name + '"]').prop('checked')));
    } else if(input_name.search('calculation') >= 0) {
      $filter_form_scores.each(function (index, element) {
        element.disabled = !($('[name="calculation"]').prop('checked') &&
                             $('[name="' + element.getAttribute('name') + '"]').prop('checked'));
      });
    } else if(input_name.search('education_base_id') >= 0) {
      check_education_base_id_pzso();
    }

    filter_detect_selected_menus(input_name);

    active_subjects_count = $filter_form_names.find('label.active:visible').length
    filled_subjects_score_count = $('.subject_score').filter((index, input) => !input.attributes['disabled'] && input.value).length

    if(filled_subjects_score_count === 0 || active_subjects_count === filled_subjects_score_count) {
      setTimeout(
        function () {
          $('#calculator_specialities_filter_submit').click().removeClass('d-none')

          if($filter_form[0].checkValidity()) {
            $filter_form_checkboxes.find('input').prop('disabled', true);
            $filter_form_checkboxes.addClass('disabled');
            $filter_form_selects.prop('disabled', true);
            $filter_form_scores.prop('readonly', true);

            $('#specialities_count').html("<p class='text-center'><span class='spinner-border spinner-border-sm text-info'></span></p>");
          }
        },
        0
      )
    }
  }

  function filter_detect_selected_menus(input_name) {
    let $input_menu_header, input_group = input_name.replace(/\[.*/, '');

    if(input_group === 'calculation') {
      if($('.subject_calculation').find('input').prop('checked')) {
        $filter_form_names.removeClass('w-100');
        $filter_form_scores.removeClass('d-none');
        $filter_form_calculation_element.removeClass('d-none');
      } else {
        $filter_form_names.addClass('w-100');
        $filter_form_scores.addClass('d-none');
        $filter_form_calculation_element.addClass('d-none');
        $filter_form_calculation_element.find('input').prop('disabled', true);
      }
      $input_menu_header = $('[filter_by=subjects], [filter_by=subjects_nmt]');
    } else {
      $input_menu_header = $('[filter_by=' + input_group + ']');
    }

    // resolved calculation & subjects conflict
    if(input_group === 'calculation' || input_group === 'subjects' || input_group === 'subjects_nmt') {
      input_group = (/calculation|subjects/);
    }

    if($filter_form.serialize().search(input_group) >= 0) {
      $input_menu_header.addClass('font-weight-bold');
      $input_menu_header.parents('.card').find('.collapse').collapse('show');
    } else {
      $input_menu_header.removeClass('font-weight-bold');
    }

    // debugger

    setTimeout(
      function () {
        $subject_names = $subjects_card_body.find('.subject_name')
        if($subject_names.find('label.active:not(.calculation_element):not(.disabled)').length >= 1) {
          $subject_names.find('label:not(.active):not(.calculation_element):not(.disabled)').addClass('disabled')
        } else {
          $subject_names.find('label:not(.active):not(.calculation_element):not(.disabled)').removeClass('disabled')
        }

        $subject_evi_efvv_names = $subjects_evi_efvv_card_body.find('.subject_name')
        if($subject_evi_efvv_names.find('label.active:not(.calculation_element):not(.disabled)').length >= 2) {
          $subject_evi_efvv_names.find('label:not(.active):not(.calculation_element):not(.disabled)').addClass('disabled')
        } else {
          $subject_evi_efvv_names.find('label:not(.active):not(.calculation_element):not(.disabled)').removeClass('disabled')
        }
      },
      100
    )
  }
});



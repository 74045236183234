function resetValues($el) {
  $el.find('input[type=text], input[type=number]').val('');
  $el.find('input[type=checkbox]').prop('checked', false);
}

function clearFileInput(fileInput) {
  if(fileInput.value){
    try{
      fileInput.value = ''; //for IE11, latest Chrome/Firefox/Opera...
    }catch(err){ }
    if(fileInput.value){ //for IE5 ~ IE10
      var form = document.createElement('form'),
        parentNode = fileInput.parentNode, ref = fileInput.nextSibling;
      form.appendChild(fileInput);
      form.reset();
      parentNode.insertBefore(fileInput,ref);
    }
  }
}

$(document).ready(function () {
  $('.avatar-input').change(function (event) {
    let file = event.target;
    console.log(file)
    console.log(file.files[0])

    if ((file.files[0].size / 1024 / 1024) > 1) {
      alert('Максимально допустимий розмір - 1 МБ');
      clearFileInput(file)
      file.parentNode.querySelector('.avatar-input').innerHTML = 'Максимально допустимий розмір - 1 МБ'
    }

    if (file.files[0].type !== 'image/jpeg' && file.files[0].type !== 'image/png') {
      alert('Допустимий формат зображення JPEG (.jpg, .jpeg, .png)');
      clearFileInput(file)
      file.parentNode.querySelector('.avatar-input').innerHTML = 'Допустимий формат зображення JPEG (.jpg, .jpeg, .png)'
    }
  });
})
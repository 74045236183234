function resetValues($el) {
  $el.find('input[type=text], input[type=number]').val('');
  $el.find('input[type=checkbox]').prop('checked', false);
}

function clearFileInput(fileInput) {
  if(fileInput.value){
    try{
      fileInput.value = ''; //for IE11, latest Chrome/Firefox/Opera...
    }catch(err){ }
    if(fileInput.value){ //for IE5 ~ IE10
      var form = document.createElement('form'),
        parentNode = fileInput.parentNode, ref = fileInput.nextSibling;
      form.appendChild(fileInput);
      form.reset();
      parentNode.insertBefore(fileInput,ref);
    }
  }
}

function checkEFVV() {
  let $specialitySelect = $('#statement_of_examination_letter_passing_speciality');
  let $passingEFVV = $('#statement_of_examination_letter_passing_efvv_required')
  let $passingEFVVHelp = $('#passingEFVVHelp')

  let specialities = [
    "011 Освітні, педагогічні науки",
    "012 Дошкільна освіта",
    "013 Початкова освіта",
    "014.01 Середня освіта. Українська мова і література",
    "014.021 Середня освіта. Англійська мова та зарубіжна література",
    "014.022 Середня освіта. Німецька мова та зарубіжна література",
    "014.026 Середня освіта. Угорська мова та зарубіжна література",
    "014.03 Середня освіта. Історія та громадянська освіта",
    "014.03 Середня освіта. Історія та громадянська освіта",
    "014.04 Середня освіта. Математика",
    "014.04 Середня освіта. Математика",
    "014.05 Середня освіта. Біологія та здоров’я людини",
    "014.06 Середня освіта. Хімія",
    "014.07 Середня освіта. Географія",
    "014.08 Середня освіта. Фізика та астрономія",
    "014.08 Середня освіта. Фізика та астрономія",
    "014.11 Середня освіта. Фізична культура",
    "016.02 Спеціальна освіта. Корекційна психопедагогіка",
    "051 Економіка",
    "052 Політологія",
    "053 Психологія",
    "054 Соціологія",
    "061 Журналістика",
    "071 Облік і оподаткування",
    "072 Фінанси, банківська справа, страхування та фондовий ринок",
    "073 Менеджмент",
    "075 Маркетинг",
    "076 Підприємництво та торгівля",
    "081 Право",
    "121 Інженерія програмного забезпечення",
    "122 Комп'ютерні науки",
    "123 Комп'ютерна інженерія",
    "124 Системний аналіз",
    "125 Кібербезпека та захист інформації",
    "126 Інформаційні системи та технології",
    "241 Готельно-ресторанна справа",
    "242 Туризм і рекреація",
    "281 Публічне управління та адміністрування",
    "291 Міжнародні відносини, суспільні комунікації та регіональні студії",
    "292 Міжнародні економічні відносини",
    "293 Міжнародне право",
    "Інша спеціальність"
  ]

  if (!specialities.includes($specialitySelect.val())) {
    if ($passingEFVV.prop('checked')) $passingEFVV.click();

    $passingEFVV.prop('disabled', true)
    $passingEFVVHelp.removeClass('d-none')
  } else {
    $passingEFVV.prop('disabled', false)
    $passingEFVVHelp.addClass('d-none')
  }
}

$(document).ready(function () {
  // checkEFVV();
  //
  // $('#statement_of_examination_letter_passing_speciality').change(function (event) {
  //   checkEFVV();
  // });

  $('#statement_of_examination_letter_passing_evi_required').on('change', function (event) {
    let $passingEVI = $('#passingEVI');

    if(event.target.checked) {
      $passingEVI.find('input').prop('disabled', false);
      $passingEVI.find('select').prop('disabled', false);
      $passingEVI.collapse('show');
    } else {
      $passingEVI.find('input').prop('disabled', true);
      $passingEVI.find('select').prop('disabled', true);
      $passingEVI.collapse('hide');
      // resetValues($passingEVI)
    }
  });

  $('#statement_of_examination_letter_passing_efvv_required').on('change', function (event) {
    let $passingEFVV = $('#passingEFVV');

    if(event.target.checked) {
      // if (confirm("Для здобуття ступеня магістра за спеціальністю 081 «Право» приймаються особи, які здобули ступінь бакалавра з спеціальностей 081 «Право» або 293 «Міжнародне право», напрямів 6.030401 «Правознавство» або 6.030202 «Міжнародне право».\n\n Ви підтверджуєте виконання цих вимог?")) {
        $passingEFVV.find('input').prop('disabled', false);
        $passingEFVV.find('select').prop('disabled', false);
        $passingEFVV.collapse('show');
      // } else {
      //   event.target.checked = false
      // }
    } else {
      $passingEFVV.find('input').prop('disabled', true);
      $passingEFVV.find('select').prop('disabled', true);
      $passingEFVV.collapse('hide');
      // resetValues($passingEFVV)
    }
  });


  // $('#passing_efvv_requirements').on('change', function (event) {
  //   if(event.target.checked) {
  //     if (confirm("Для здобуття ступеня магістра за спеціальністю 081 «Право» приймаються особи, які здобули ступінь бакалавра з спеціальностей 081 «Право» або 293 «Міжнародне право», напрямів 6.030401 «Правознавство» або 6.030202 «Міжнародне право».\n\n Ви підтверджуєте виконання цих вимог?")) {
  //     } else {
  //       event.target.checked = false
  //     }
  //   }
  // });


  $('#statement_of_examination_letter_passing_extra_conditions_required').on('change', function (event) {
    let $passingExtraConditions = $('#passingExtraConditions');

    if(event.target.checked) {
      $passingExtraConditions.find('input').prop('disabled', false);
      $passingExtraConditions.collapse('show');
    } else {
      $passingExtraConditions.find('input').prop('disabled', true);
      $passingExtraConditions.collapse('hide');
      // resetValues($passingExtraConditions)
    }
  });

  $('[name="statement_of_examination_letter[left_education_completed]"]').on('change', function (event) {
    let $leftEducationNotCompleted = $('#leftEducationNotCompleted');
    let $leftEducationCompleted = $('#leftEducationCompleted');

    if(event.target.value === 'true') {
      $leftEducationNotCompleted.find('input').prop('disabled', true);
      $leftEducationNotCompleted.find('select').prop('disabled', true);
      $leftEducationNotCompleted.collapse('hide');
      // resetValues($leftEducationNotCompleted)

      $leftEducationCompleted.find('input').prop('disabled', false);
      $leftEducationCompleted.collapse('show');

    } else {
      $leftEducationCompleted.find('input').prop('disabled', true);
      $leftEducationCompleted.collapse('hide');
      // resetValues($leftEducationCompleted)

      $leftEducationNotCompleted.find('input').prop('disabled', false);
      $leftEducationNotCompleted.find('select').prop('disabled', false);
      $leftEducationNotCompleted.collapse('show');
    }
  });

  $('[name="statement_of_examination_letter[delivery_required]"]').on('change', function (event) {
    let $deliveryRequired = $('#deliveryRequired');

    if(event.target.value === 'true') {
      $deliveryRequired.find('input').prop('disabled', false);
      $deliveryRequired.collapse('show');
    } else {
      $deliveryRequired.find('input').prop('disabled', true);
      $deliveryRequired.collapse('hide');
      // resetValues($deliveryRequired)
    }
  });

  $('#statement_of_examination_letter_left_education_document_institution_type').on('change', function (event) {
    let $leftInstitutionUzhNU = $('#leftInstitutionUzhNU');
    let $leftInstitutionMilitary = $('#leftInstitutionMilitary');
    let $leftInstitutionOther = $('#leftInstitutionOther');

    if(event.target.value === 'ДВНЗ "УжНУ"') {
      $leftInstitutionMilitary.find('input').prop('disabled', true);
      $leftInstitutionMilitary.collapse('hide');

      $leftInstitutionOther.find('input').prop('disabled', true);
      $leftInstitutionOther.collapse('hide');

      $leftInstitutionUzhNU.find('input').prop('disabled', false);
      $leftInstitutionUzhNU.collapse('show');
    } else if (event.target.value === 'Військовий заклад вищої освіти') {
      $leftInstitutionUzhNU.find('input').prop('disabled', true);
      $leftInstitutionUzhNU.collapse('hide');

      $leftInstitutionOther.find('input').prop('disabled', true);
      $leftInstitutionOther.collapse('hide');

      $leftInstitutionMilitary.find('input').prop('disabled', false);
      $leftInstitutionMilitary.collapse('show');
    } else if (event.target.value === 'Інший ЗВО') {
      $leftInstitutionUzhNU.find('input').prop('disabled', true);
      $leftInstitutionUzhNU.collapse('hide');

      $leftInstitutionMilitary.find('input').prop('disabled', true);
      $leftInstitutionMilitary.collapse('hide');

      $leftInstitutionOther.find('input').prop('disabled', false);
      $leftInstitutionOther.collapse('show');
    } else {
      $leftInstitutionUzhNU.find('input').prop('disabled', true);
      $leftInstitutionUzhNU.collapse('hide');

      $leftInstitutionMilitary.find('input').prop('disabled', true);
      $leftInstitutionMilitary.collapse('hide');

      $leftInstitutionOther.find('input').prop('disabled', true);
      $leftInstitutionOther.collapse('hide');
    }
  });

  $('.avatar-input').change(function (event) {
    let file = event.target;
    console.log(file)
    console.log(file.files[0])

    if ((file.files[0].size / 1024 / 1024) > 1) {
      alert('Максимально допустимий розмір - 1 МБ');
      clearFileInput(file)
      file.parentNode.querySelector('.avatar-input').innerHTML = 'Максимально допустимий розмір - 1 МБ'
    }

    if (file.files[0].type !== 'image/jpeg' && file.files[0].type !== 'image/png') {
      alert('Допустимий формат зображення JPEG (.jpg, .jpeg, .png)');
      clearFileInput(file)
      file.parentNode.querySelector('.avatar-input').innerHTML = 'Допустимий формат зображення JPEG (.jpg, .jpeg, .png)'
    }
  });

  // $('#statement_of_examination_letter_passing_evi_location').change(function (event) {
  //   $('#statement_of_examination_letter_passing_efvv_location').val(event.target.value);
  // })
  //
  // $('#statement_of_examination_letter_passing_efvv_location').change(function (event) {
  //   $('#statement_of_examination_letter_passing_evi_location').val(event.target.value);
  // })

  $('#statement_of_examination_letter_passing_efvv_location, #statement_of_examination_letter_passing_efvv_exam_1, #statement_of_examination_letter_passing_efvv_exam_2').change(function (event) {
    let location = $('#statement_of_examination_letter_passing_efvv_location').val()
    let exam_1 = $('#statement_of_examination_letter_passing_efvv_exam_1').val()
    let exam_2 = $('#statement_of_examination_letter_passing_efvv_exam_2').val()

    let list_ex_countries = [
      'Словацька Республіка, м. Братислава',
      'Чеська Республіка, м. Брно',
      'Чеська Республіка, м. Прага'
    ]
    let list_ex_sujects = [
      'Політологія та міжнародні відносини',
      'Право та міжнародне право',
      'Економіка та міжнародна економіка',
      'Психологія та соціологія',
      'Інформаційні технології'
    ]

    if (
      location != '' &&
      list_ex_countries.includes(location) && [exam_1, exam_2].includes('Педагогіка та психологія') ||
      (list_ex_sujects.includes(exam_1) || list_ex_sujects.includes(exam_2)) && location === 'Федеративна Республіка Німеччина, м. Берлін'
    ) {
      alert('Обране предметне тестування не проводиться в обраному населеному пункті')
    }

  })
});

window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();
// require("@rails/activestorage").start();
require("select2");
require("channels");

import Inputmask from 'inputmask'
import bsCustomFileInput from 'bs-custom-file-input'
import './bootstrap_custom'
import './calculator'
import './statement_base'
import './statement_of_examination_letters'
import './statement_of_registration_cards'

$(function(){
  $('#js-heightControl').css('height', $(window).height() - $('html').height() +'px');
  $("[data-toggle='tooltip']").tooltip();
});

$(document).ready(function(){
  Inputmask().mask(document.querySelectorAll("input"));
});

let BUDGET = [
  // {
  //   key: 'Біологія',
  //   value: ['05 липня, 09:00', '10 липня, 12:00']
  // },
  // {
  //   key: 'Географія',
  //   value: ['08 липня, 09:00', '12 липня, 12:00']
  // },
  // {
  //   key: 'Іноземна мова (англійська, німецька, французька)',
  //   value: ['08 липня, 12:00', '12 липня, 09:00']
  // },
  // {
  //   key: 'Історія України',
  //   value: ['02 липня, 09:00', '13 липня, 12:00']
  // },
  // {
  //   key: 'Математика',
  //   value: ['06 липня, 09:00', '13 липня, 09:00']
  // },
  // {
  //   key: 'Українська мова та література',
  //   value: ['07 липня, 09:00', '10 липня, 09:00']
  // },
  // {
  //   key: 'Фізика',
  //   value: ['05 липня, 12:00', '09 липня, 09:00']
  // },
  // {
  //   key: 'Хімія',
  //   value: ['02 липня, 12:00', '09 липня, 12:00']
  // },
  {
    key: 'Угорська мова',
    value: ['11 серпня, 12:00']
  },
  {
    key: 'Румунська мова',
    value: ['10 серпня, 12:00']
  }
];

let CONTRACT = [
  // {
  //   key: 'Біологія',
  //   value: ['10 серпня, 09:00']
  // },
  // {
  //   key: 'Географія',
  //   value: ['09 серпня, 14:00']
  // },
  // {
  //   key: 'Іноземна мова (англійська, німецька, французька)',
  //   value: ['09 серпня, 12:00']
  // },
  // {
  //   key: 'Історія України',
  //   value: ['11 серпня, 09:00']
  // },
  // {
  //   key: 'Математика',
  //   value: ['09 серпня, 09:00']
  // },
  // {
  //   key: 'Українська мова та література',
  //   value: ['11 серпня, 12:00']
  // },
  // {
  //   key: 'Фізика',
  //   value: ['10 серпня, 14:00']
  // },
  // {
  //   key: 'Хімія',
  //   value: ['10 серпня, 12:00']
  // },
  // {
  //   key: 'Угорська мова, Румунська мова',
  //   value: ['11 серпня, 12:00']
  // }
  {
    key: 'Угорська мова',
    value: ['16 серпня, 12:00']
  },
  {
    key: 'Румунська мова',
    value: ['15 серпня, 12:00']
  }
];

$(document).ready(function () {
  bsCustomFileInput.init();

  $('.entry_speciality').change(function (event) {
    let el = event.target;

    if (el.value === '017 Фізична культура і спорт') {
      $('.entry_type_budget').closest('div').removeClass('d-none');
      $('.entry_type_contract').closest('div').removeClass('d-none');

      $('.entry_dates_017_budget').addClass('d-none');
      $('.entry_dates_017_contract').addClass('d-none');
      $('.entry_dates_262_contract').addClass('d-none');
    } else if (el.value === '262 Правоохоронна діяльність') {
      $('.entry_type_budget').closest('div').addClass('d-none');
      $('.entry_type_contract').closest('div').removeClass('d-none');
      $('.entry_dates_017_budget').addClass('d-none');
      $('.entry_dates_017_contract').addClass('d-none');
      $('.entry_dates_262_contract').addClass('d-none');
    } else {
      $('.entry_type_budget').closest('div').addClass('d-none');
      $('.entry_type_contract').closest('div').addClass('d-none');

      $('.entry_dates_017_budget').addClass('d-none');
      $('.entry_dates_017_contract').addClass('d-none');
      $('.entry_dates_262_contract').addClass('d-none');
    }

    $('[name="statement_of_the_creative_competition[entry_type]"]').prop('checked',false)
    $('[name="statement_of_the_creative_competition[entry_date]"]').val('')
  });

  $('.entry_type_budget').click(function (event) {
    $('.entry_dates_017_budget').removeClass('d-none');
    $('.entry_dates_017_contract').addClass('d-none');
    $('.entry_dates_262_contract').addClass('d-none');
    $('.entry_dates_017_budget').find('select').prop('required', true);
    $('.entry_dates_017_contract').find('select').prop('required', false);
    $('.entry_dates_262_contract').find('select').prop('required', false);
    $('.entry_dates_017_budget').find('select').prop('disabled', false);
    $('.entry_dates_017_contract').find('select').prop('disabled', true);
    $('.entry_dates_262_contract').find('select').prop('disabled', true);
    $('[name="statement_of_the_creative_competition[entry_date]"]').val('')
  });

  $('.entry_type_contract').click(function (event) {
    $('.entry_dates_017_budget').addClass('d-none');
    $('.entry_dates_017_budget').find('select').prop('required', false);
    $('.entry_dates_017_budget').find('select').prop('disabled', true);

    if ($('.entry_speciality').val() === '017 Фізична культура і спорт') {
      $('.entry_dates_262_contract').addClass('d-none');
      $('.entry_dates_262_contract').find('select').prop('required', false);
      $('.entry_dates_262_contract').find('select').prop('disabled', true);

      $('.entry_dates_017_contract').removeClass('d-none');
      $('.entry_dates_017_contract').find('select').prop('required', true);
      $('.entry_dates_017_contract').find('select').prop('disabled', false);
    } else if ($('.entry_speciality').val() === '262 Правоохоронна діяльність') {
      $('.entry_dates_017_contract').addClass('d-none');
      $('.entry_dates_017_contract').find('select').prop('required', false);
      $('.entry_dates_017_contract').find('select').prop('disabled', true);

      $('.entry_dates_262_contract').removeClass('d-none');
      $('.entry_dates_262_contract').find('select').prop('required', true);
      $('.entry_dates_262_contract').find('select').prop('disabled', false);
    }
    $('[name="statement_of_the_creative_competition[entry_date]"]').val('')
  });

  $('.ec_entry_type_budget, .ec_entry_type_contract').click(function (event) {
    $('.entry_subject').prop('disabled', false).val('');
    $('.entry_date').prop('disabled', true).val('');
  });

  $('.entry_subject').change(function (event) {
    var dates, subject = event.target, subject_date = subject.parentNode.querySelector('.entry_date');

    if (subject.value === ''){
      subject_date.value = '';
      subject_date.disabled = true;
    } else {
      if ($('.ec_entry_type_budget').prop('checked')) {
        dates = BUDGET;
      } else if($('.ec_entry_type_contract').prop('checked')) {
        dates = CONTRACT;
      } else {
        return ;
      }

      dates = dates.find(date => date.key === subject.value).value;

      $(subject_date).find('option').remove();
      subject_date.append(new Option(dates[0], dates[0]));
      subject_date.append(new Option(dates[1], dates[1]));
      subject_date.disabled = false;
    }
  });

  var date_1 = $('#statement_of_the_entrance_competition_entry_date_1').val(),
    date_2 = $('#statement_of_the_entrance_competition_entry_date_2').val(),
    date_3 = $('#statement_of_the_entrance_competition_entry_date_3').val(),
    date_4 = $('#statement_of_the_entrance_competition_entry_date_4').val();
  $('.entry_subject').trigger('change');
  $('#statement_of_the_entrance_competition_entry_date_1').val(date_1);
  $('#statement_of_the_entrance_competition_entry_date_2').val(date_2);
  $('#statement_of_the_entrance_competition_entry_date_3').val(date_3);
  $('#statement_of_the_entrance_competition_entry_date_4').val(date_4);

  $('.custom-file-input').change(function (event) {
    let file = event.target;

    if ((file.files[0].size / 1024 / 1024) > 5) {
      alert('Максимально допустимий розмір - 5 МБ');

      if(file.value){
        try{
          file.value = ''; //for IE11, latest Chrome/Firefox/Opera...
        }catch(err){ }
        if(file.value){ //for IE5 ~ IE10
          var form = document.createElement('form'),
            parentNode = file.parentNode, ref = file.nextSibling;
          form.appendChild(file);
          form.reset();
          parentNode.insertBefore(file,ref);
        }
      }

      file.parentNode.querySelector('.custom-file-label').innerHTML = 'Максимально допустимий розмір - 5 МБ'
    }
  })
});

// import SCSS
import '../stylesheets/application'

